import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import FailureMenu from './_menu.js'

const Failure2022TourPage = ({ data }) => (
  <Layout bgClass="clients-failure">
    <FailureMenu pageName="2022_tour" />
    <div className="textPageDetails">
      <p>
        For our third tour producing posters for Failure, the band continued with their "Dark Sci-Fi" aesthetic. The band requested one print for several dates at once, allowing us to have artists spend more time per image, and ease logistics and delivery costs.  The year's tour concluded with a poster for their virtual event, "We Are Hallucinations".
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

Failure2022TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Failure2022TourPage

export const pageQuery = graphql`
  query Failure2022TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/failure/2022_tour/"}}
      sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
