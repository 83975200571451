import React from 'react'
import { Link } from 'gatsby'

const FailureMenu = (props) => (
  <div>
  <h2 className="major">Failure</h2>
  <ul className="bigList">
  <li className={props.pageName === '2016_tour' ? 'current' : ''}><Link to="/clients/failure/2016_tour">2016 US Tour</Link></li>
  <li className={props.pageName === '2019_tour' ? 'current' : ''}><Link to="/clients/failure/2019_tour">2019 US Tour</Link></li>
  <li className={props.pageName === '2022_tour' ? 'current' : ''}><Link to="/clients/failure/2022_tour">2022 US Tour</Link></li>
  </ul>
  </div>
)

export default FailureMenu
